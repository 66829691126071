import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark as formatStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
//import { dark as formatStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "./App.scss";

const size = 4;

interface State {
  fullText: string;
  currentLen: number;
  currentText: string;
}

interface Props {}

export default class App extends React.Component<Props, State> {
  private messagesEnd = React.createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      fullText: ``,
      currentText: ``,
      currentLen: 0,
    };
  }

  public componentDidMount() {
    fetch(`/code.txt`)
      .then((r) => r.text())
      .then((text) => {
        this.setState((prev) => ({ ...prev, fullText: text }));
      })
      .catch((err) => console.warn(err));

    window.addEventListener("keydown", this.keyDown);
    this.scrollToBottom();
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.keyDown);
  }

  public render() {
    return (
      <div className="App">
        <code className="code">
          <SyntaxHighlighter language="cpp" style={formatStyle}>
            {this.state.currentText}
          </SyntaxHighlighter>
        </code>
        <div
          style={{ float: "left", clear: "both" }}
          ref={this.messagesEnd}
        ></div>
      </div>
    );
  }

  private keyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      default:
        this.setState((prev) => {
          const len = prev.currentLen + size;
          return {
            ...prev,
            currentLen: len,
            currentText: prev.fullText.substr(0, len),
          };
        });
        event.preventDefault();
        event.stopPropagation();
    }
  };

  scrollToBottom = () => {
    this.messagesEnd.current?.scrollIntoView({});
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }
}
